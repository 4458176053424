* {
 box-sizing: border-box;
}

*:before,
*:after {
 box-sizing: border-box;
}

html, body {
 height: 100%;
 position: relative;
 }

.main-container {
 min-height: 100vh;
 overflow: hidden;
 display: block;
 position: relative;
 padding-bottom: 200px;
}

footer {
  width: 100%;
  position: absolute;
  bottom: 0;
}

/* notice bar */

.bd-notice {
  background-color: #f8d210;
  padding: 1em;
  text-align: center;
  color: #fff;

  a {
    color: #fff;
  }
}

/* icons */

.orange {
  color: #f66a0a;
}

.grey {
  color: #828282;
}

.svg-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
}
